import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
import * as main from '../services/main.service';
import * as constant from '../services/constant';
const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/welcome',
    name: 'Welcome',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Welcome.vue" */'../views/Welcome.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Dashboard.vue" */'../views/Dashboard.vue')
  },
  {
    path: '/ListLotto',
    name: 'ListLotto',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ListLotto.vue" */'../views/ListLotto.vue')
  },
  {
    path: '/AddLottery',
    name: 'AddLottery',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "AddLottery.vue" */'../views/AddLottery.vue')
  },
  {
    path: '/ListDraw',
    name: 'ListDraw',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ListDraw.vue" */'../views/ListDraw.vue')
  },
  {
    path: '/ListUser',
    name: 'ListUser',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ListUser.vue" */'../views/ListUser.vue')
  },
  {
    path: '/ListOrders',
    name: 'ListOrders',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ListOrders.vue" */'../views/ListOrders.vue')
  },
  {
    path: '/ConfirmLotto',
    name: 'ConfirmLotto',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ConfirmLotto.vue" */'../views/ConfirmLotto.vue')
  },
  {
    path: '/VerifyLotto',
    name: 'VerifyLotto',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "VerifyLotto.vue" */'../views/VerifyLotto.vue')
  },
  { path: '/', redirect: '/login' },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  }
]
//ส่ง jwt ไป vetify ทุกครั้งก่อนเข้า page เพื่อให่มั่นใจว่า kry ยังไม่หมดอายุหรือโดนปลอมแปลงถ้าไม่ผ่าน ให้ลบ และ rediract ไป login
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let getUser = store.getters['auth/getUser']
    if(!getUser){
      store.dispatch('auth/NotFoundUser')
      next({
        path: '/login'
      })
      return
    }else{

    }
  }
  
  
  // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //   let getUser = store.getters['auth/getUser']
  //   console.log(getUser)
  //   if(getUser.ROLE !=='Admin'){
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{

  //   }
      
  // }

  next() // make sure to always call next()!
  
})
export default router
