<template>
    <section>
        <a @click="changePassword" class="nav-author__signout" data-toggle="modal" data-target="#edit-change">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
            Change Password
        </a>

      <!-- Modal Edit -->
                             <div  class="modal fade edit-change" id="edit-change" role="dialog" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content  radius-xl">
                                        <div class="modal-header">
                                            <h6 class="modal-title fw-500" id="staticBackdropLabel">{{ t('editdbl',{},{locale:getLocal}) }}</h6>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                           <h1>hello</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal Edit -->
    </section>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'EditPassword',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  props: [
    
  ],
  mounted() {

  },
  data: function () {
    return {
        order:'drawNumber',
        dir:'ASC',
        status:'',
        drawNumber:'',
        drawDate:'',
        isLuckyNumber:'N',
        onSearch:false,
        count:0,
        PaginationData :{},

        listData:[],
        
        editData:{
            name:'',
            mobile:''   
        },    
        
    }
    
  },
  methods: {
    async changePassword(){
      try {
          // $('#edit-change').modal('show');
          // this.showData()
      } catch (error) {
          
      }
    }
  },
  computed: {
        getLocal:function(){
        return this.$store.getters['display/getLocal']
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>