<template>
    <main class="main-content" >

        <div class="signUP-admin">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-6 col-md-6 p-0 d-flex justify-content-center">
                        
                            
                                <img class="img-fluid img-login "  src="images/login_banner.png" alt="img" />
                    
                    </div>
                    <div class="col-6 col-md-6 p-0">
                        <div class="d-flex align-items-center justify-content-center login-bg">   
                            <div class="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                                    <div class="row px-5 mb-3 ">
                                        <img class="w-100"  src="images/logo_renny_login.png" alt="img" />
                                    </div>
                                    <div class="row justify-content-center ">
                                        <div class="col-12 border-login">
                                            <div class="edit-profile mt-md-25 mt-0">
                                                <div class="card border-0">
                                                    
                                                    <div class="card-body">
                                                        <div class="edit-profile__body">
                                                            <div class="form-group mb-20">
                                                                <label for="username" class="font-weight-bold" >Username</label>
                                                                <input type="text" class="form-control" v-model="loginformData.username"  id="username" placeholder="Username">
                                                            </div>
                                                            <div class="form-group mb-15">
                                                                <label for="password-field" class="font-weight-bold">password</label>
                                                                <div class="position-relative">
                                                                    <input v-on:keyup.enter="loginFN" v-model="loginformData.password" :type="passwordFieldType" class="form-control" name="password" >
                                                                    <div v-if="passwordFieldType=='password'" @click="viewpass" class="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></div>
                                                                    <div @click="viewpass" class="fa fa-fw fa-eye text-light fs-16 field-icon toggle-password2"></div>
                                                                </div>
                                                            </div>
                                                            <div class="signUp-condition signIn-condition">
                                                                <div class="checkbox-theme-default custom-checkbox ">
                                                                    <input class="checkbox"   type="checkbox" id="check-1">
                                                                    <label for="check-1">
                                                                        <span class="checkbox-text">Remember me</span>
                                                                    </label>
                                                                </div>
                                                                <a href="#" class="forget-pass font-weight-normal" >forget password</a>
                                                            </div>
                                                            <div class="d-flex justify-content-center">
                                                                <button @click="loginFN" class="btn btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn btn-renny ">
                                                                    sign in
                                                                </button>
                                                            </div>
                                                            <!-- <p class="social-connector text-center mb-sm-25 mb-15  mt-sm-30 mt-20"><span>Or</span></p>
                                                            <div class="button-group d-flex align-items-center justify-content-md-start justify-content-center">
                                                                <ul class="signUp-socialBtn">
                                                                    <li>
                                                                        <button class="btn text-dark px-30"><img class="svg" src="img/svg/google.svg" alt="img" /> Sign up with
                                                                            Google</button>
                                                                    </li>
                                                                    <li>
                                                                        <button class=" radius-md wh-48 content-center"><img class="svg" src="img/svg/facebook.svg" alt="img" /></button>
                                                                    </li>
                                                                    <li>
                                                                        <button class="radius-md wh-48 content-center"><img class="svg" src="img/svg/twitter.svg" alt="img" /></button>
                                                                    </li>
                                                                </ul>
                                                            </div> -->
                                                        </div>
                                                    </div><!-- End: .card-body -->
                                                </div><!-- End: .card -->
                                            </div><!-- End: .edit-profile -->
                                        </div><!-- End: .col-xl-5 -->
                                </div>
                            </div>
                        </div><!-- End: .signUp-admin-right  -->
                    </div><!-- End: .col-xl-8  -->
                </div>
            </div>
        </div><!-- End: .signUP-admin  -->

    </main>
</template>

<script>

import * as serviceMain  from '@/services/main.service';
import * as serviceAPI  from '@/services/API.service';
export default {
  name: 'LoginLayout',
  data: function () {
    return {
      loginformData:{
          username:'',
          password:''
      },
      passwordFieldType:'password'
    }
  },
  methods: {  
    // async login(){
    // let data = {
    //     "installId":"DEF1593453121338"
    // }
    // let getAPI = await serviceAPI.call_API('post','renny-top-up/kiosk/login',data,'auth');
    // console.log(getAPI)
    // },
    async loginFN() {
        try {
            let data = {        
                "username": this.loginformData.username,
                "password": this.loginformData.password
            }
            let getAPI = await serviceAPI.call_API('post','lotto/api/v1/login',data);
            let getEncodeAccess_token = btoa(btoa(getAPI.data.token))
            localStorage.setItem('user', getEncodeAccess_token);
            this.$store.dispatch('auth/login')
            this.$router.push({ name: 'Welcome'});
        } catch (error) {
            console.log("error")
            console.log(error)
            serviceMain.showErrorAlert(this,error)
        }
    },
    cklogIn:async function () {
        if (this.$store.getters['auth/getloggedIn']) {
            this.$router.push({ name: 'Welcome'});
        }
    },
    viewpass() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    }

  },
  mounted() {
        this.cklogIn()

  }
}

</script>
<style scoped lang="scss">
.img-login{
    height: 100vh;
}
.login-bg{
    min-height: 100vh;
}
.border-login{
    border:2px lightgray solid;
    border-radius: 8px;
}
.btn-renny{
    background: linear-gradient(180deg, #F4DC00 0%, #FFC700 56.77%);
    border-radius: 4px;
}
.forget-pass{
    color: #2F55A4 !important;

}
</style>