<template>
<header class="header-top">
    <nav class="navbar navbar-light">
        <div class="navbar-left">
            <a @click="toggleAside" class="sidebar-toggle">
                <img class="svg" src="/img/svg/bars.svg" alt="img"></a>
            <a class="navbar-brand" href="#"><img class="svg dark" style="max-height: 30px;" src="/images/Renny.png" alt="svg"></a>
            <div class="d-none d-sm-block">
            <form action="/" class="search-form ">
                <span data-feather="search"></span>
                <input class="form-control mr-sm-2 box-shadow-none" type="search" placeholder="Search..." aria-label="Search">
            </form>
            </div>
        </div>
        <!-- ends: navbar-left -->

        <div class="navbar-right">
            <ul class="navbar-right__menu">

                <li class="nav-notification">
                    <a :class="`local pointer ${getLocal=='en'?'active':''}`" @click="setLocal('en')" >EN</a><span>|</span><a :class="`local pointer ${getLocal=='th'?'active':''}`" @click="setLocal('th')">TH</a>
                </li>
                <li class="nav-notification">
                    <div class="dropdown-custom">
                        <a href="javascript:;" class="nav-item-toggle">
                            <span data-feather="bell"></span></a>
                        <div class="dropdown-wrapper">
                            <h2 class="dropdown-wrapper__title">Notifications <span class="badge-circle badge-warning ml-1">0</span></h2>
                            <ul>
                                <!-- <li class="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                                    <div class="nav-notification__type nav-notification__type--primary">
                                        <span data-feather="inbox"></span>
                                    </div>
                                    <div class="nav-notification__details">
                                        <p>
                                            <a href="" class="subject stretched-link text-truncate" style="max-width: 180px;">James</a>
                                            <span>sent you a message</span>
                                        </p>
                                        <p>
                                            <span class="time-posted">5 hours ago</span>
                                        </p>
                                    </div>
                                </li>
                    -->
                            </ul>
                            <a href="" class="dropdown-wrapper__more">See all incoming activity</a>
                        </div>
                    </div>
                </li>

                <!-- ends: .nav-flag-select -->
                <li class="nav-author">
                    <div class="dropdown-custom">
                        <a href="javascript:;" class="nav-item-toggle "><img class="img-nav-profile rounded-circle"  src="/images/Renny.png" alt="" ></a>
                        <div class="dropdown-wrapper">
                            <div class="nav-author__info">
                                <div class="author-img">
                                    <img class="img-nav-profile rounded-circle"  src="/images/Renny.png" alt="" >
                                </div>
                                <div>
                                    <h6>{{$store.getters['auth/getUser'].name}} </h6>
                                    <span>{{$store.getters['auth/getUser'].username}}</span>
                                    <br>
                                    <span>version:0.1.18</span>
                                </div>
                            </div>
                            <div class="nav-author__options">
                                <ul>
                                    
                                    <li>
                                        <a @click="BTNlogout" class="nav-author__signout">
                                        <span data-feather="log-out"></span> Sign Out
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <a href="">
                                            <span data-feather="settings"></span> Settings</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="key"></span> Billing</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="users"></span> Activity</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="bell"></span> Help</a>
                                    </li> -->
                                </ul>

                                

                            </div>
                        </div>
                        <!-- ends: .dropdown-wrapper -->
                    </div>
                </li>
                <!-- ends: .nav-author -->
            </ul>
          
        </div>
        <!-- edit profile -->
        <!-- ends: .navbar-right -->
        
    </nav>
</header>

</template>

<script>
import * as serviceAPI  from '../services/API.service';
import * as serviceMain  from '../services/main.service'
import * as constant from '../services/constant';;
import EditPassword from '@/components/EditPassword.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Navbar',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    EditPassword
  },
  data: function () {
    return {
        order:'drawNumber',
        dir:'ASC',
        status:'',
        drawNumber:'',
        drawDate:'',
        isLuckyNumber:'N',
        onSearch:false,
        count:0,
        PaginationData :{},

        listData:[],
        
        editData:{
            name:'',
            mobile:''   
        },    
        
    }
    
  },
  methods: {

    async Refresh(){
        this.count=0;
        this.onSearch = false;
        this.order='drawNumber'
        this.dir='ASC'
        this.status=''
        this.drawNumber=''
        this.drawDate=''
        this.isLuckyNumber='N'
        this.showData()
    },

    async showData(){
        try {
            let data = {
                start:this.PaginationData.offset,
                length:this.PaginationData.limit,
                // start:'0',
                // length:'10',
                order:this.order,
                dir:this.dir,
                status:this.status,
                drawNumber:this.drawNumber,
                drawDate:this.drawDate,
                isLuckyNumber:this.isLuckyNumber

            }
            let getAPI = await serviceAPI.call_API('get','lotto/api/v1/listUsers',data,'auth');
            console.log(getAPI)
            this.count = getAPI.data.recordsFiltered;
            this.listData = getAPI.data.data;
            
        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },
    async editForm(e){
        $('#edit-change').modal('show');
        this.editData = {
            username:e.username,
            password:e.password,
            name:e.name,
            mobile:e.mobile
        }   
        
    },

    async sendEditData(e){
        try {
            e.preventDefault();
            let form = new FormData();
            form.append("name", this.editData.name);
            form.append("mobile", this.editData.mobile);
            
            let getAPI = await serviceAPI.call_API_FORMDATA("POST","lotto/api/v1/editProfile",form);
            
            $('#edit-change').modal('hide');
            

            
           let getData = getAPI.status.messageTH;
            serviceMain.showSuccessAlert(this,getData)
            // this.Refresh()
            

        } catch (error) {
            $('#edit-change').modal('hide');
            serviceMain.showErrorAlert(this,error)
        }
    },

    toggleAside(){
      this.$store.dispatch('display/toggle_Aside')
    },
    BTNlogout(){
      this.$store.dispatch('auth/logout')
      this.$router.push('/login');
    },
    setLocal(para_local){
        this.$i18n.locale = para_local;
        this.$store.dispatch('display/setLocal',para_local)
    }
  },
  computed: {
      getLocal:function(){
      return this.$store.getters['display/getLocal']
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.local.active{
    color: rgba(238, 166, 58, 0.75);
}
</style>
