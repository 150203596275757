import axios from 'axios';
import authHeader from './auth-header';
import * as constant from './constant';
import store from '../store/index'
const API_URL = constant.API_URL;

export async function call_API(para_method,para_url,para_data,para_auth) {
      return new Promise((resolve,reject) => {
          var gethead 
          let getToken = null
          if(para_auth){
            let getHaveLocal = localStorage.getItem(constant.TOKEN_KEY);
            if(!getHaveLocal){
              window.location = ('/login') 
              return
            } 
            try {
              getToken = atob(atob(localStorage.getItem(constant.TOKEN_KEY)))
            } catch (error) {
              localStorage.removeItem(constant.TOKEN_KEY)
              window.location = ('/login') 
              return
            }
            
           
              gethead = { 
                'Authorization': 'Bearer '+getToken, 
                'Content-Type': 'application/json',
              }
           
            
          }else{
            gethead = { 
              'Content-Type': 'application/json'
            }
          }
          
          var config = {}
          if(para_method=='get'){
            let queryString = new URLSearchParams(para_data).toString();
            config = {
              method: para_method,
              url: API_URL+para_url+"?"+queryString,
              headers: gethead
            };
          }else{
            var data = JSON.stringify(para_data);
            config = {
              method: para_method,
              url: API_URL+para_url,
              headers: gethead,
              data : data
            };
          }

          
          axios(config)
          .then(function (response) {
            let getData =JSON.parse(JSON.stringify(response.data))
            let getCode = getData.status.code
            if(getCode=='0000'){   
              resolve(getData);
            }else{
              if(getCode == '0006' || getCode == '0007' || getCode == '0008'){
                localStorage.removeItem(constant.TOKEN_KEY)
                window.location = ('/login') 
                return
              }
              console.log('API Reject')
              reject(getData.status.messageTH);
            }
          })
          .catch(function (error) {
            // let getError = error.response.data;
            // console.log(getError)
            reject(error);
          });
          
      })
  }
  export async function call_OCR(para_form_data,fileName) {
    // console.log(para_form_data);
    return new Promise((resolve,reject) => {
      var settings = {
        "url": "https://ocr.ecotechpart.com",
        "method": "POST",
        "timeout": 100000,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        "data": para_form_data,
        tryCount : 0,
        retryLimit : 20,
        success : function(response) {
          let getData =JSON.parse(response)
          resolve(getData);
        },
        error : function(xhr, textStatus, errorThrown ) {
      
          this.tryCount++;
          if (this.tryCount <= this.retryLimit) {
            if (xhr.status == 500) {
              resolve({status:0})
            } else {
              console.log("retry send "+fileName + " | count:"+this.tryCount);
              $.ajax(this);
            }
          }else{
            resolve({status:0})
          }
      }
      };

      $.ajax(settings)
    })
}
//   export async function call_OCR(para_form_data) {
//     return new Promise((resolve,reject) => {
//       var settings = {
//         "url": "https://ocr.ecotechpart.com",
//         "method": "POST",
//         "timeout": 100000,
//         "processData": false,
//         "mimeType": "multipart/form-data",
//         "contentType": false,
//         "data": para_form_data
//       };

//       $.ajax(settings).done(function (response) {
        
//         let getData =JSON.parse(response)
//         resolve(getData);
//       });
//     })
// }
export async function call_OCR_test(para_form_data) {
  return new Promise((resolve,reject) => {
    $.ajax({
        url : "https://ocr.ecotechpart.com",
        type : 'POST',
        mimeType: "multipart/form-data",
        data :  para_form_data,   
        tryCount : 0,
        retryLimit : 20,
        success : function(json) {
            console.log(json);
            resolve(json);
        },
        error : function(xhr, textStatus, errorThrown ) {
            if (textStatus == 'timeout'||xhr.status == 503) {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    //try again
                    $.ajax(this);
                    return;
                }            
                return;
            }
            resolve({status:0})
            // if (xhr.status == 500) {
            //     //handle error
            // } else {
            //     //handle error
            // }
        }
    });
  })
}
export async function call_API_FORMDATA(para_method,para_url,para_form_data) {
  return new Promise((resolve,reject) => {
    // var form = new FormData();
    // form.append("file", fileInput.files[0], "/C:/Users/USER/Downloads/rottoimg/1002/CCF_000156.jpg");
    let getToken = null
    let getHaveLocal = localStorage.getItem(constant.TOKEN_KEY);
    if(!getHaveLocal){
      window.location = ('/login') 
      return
    } 
    try {
      getToken = atob(atob(localStorage.getItem(constant.TOKEN_KEY)))
    } catch (error) {
      localStorage.removeItem(constant.TOKEN_KEY)
      window.location = ('/login') 
      return
    }
    var settings = {
      "url": API_URL+para_url,
      "method": para_method.toUpperCase(),
      "timeout": 0,
      "headers": {
          "Authorization": "Bearer "+getToken
      },
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
      "data": para_form_data
      };

    $.ajax(settings).done(function (response) {
      
      let getData =JSON.parse(response)
      let getCode = getData.status.code
      if(getCode=='0000'){   
        resolve(getData);
      }else{
        if(getCode == '0006' || getCode == '0007' || getCode == '0008'){
          localStorage.removeItem(constant.TOKEN_KEY)
          window.location = ('/login') 
          return
        }
        console.log('API Reject')
        reject(getData.status.messageTH);
      }
    });
  })
}