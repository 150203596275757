<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/login">About</router-link>
  </div> -->
  <div>
  <Navbor v-if="$store.getters['auth/getloggedIn']" />
  <main class="main-content">
    <Aside v-if="$store.getters['auth/getloggedIn']"/>
     <router-view />
  </main>
  </div>
</template>
<script>
// @ is an alias to /src
import Navbor from '@/components/Navbor.vue'
import Aside from '@/components/Aside.vue'
export default {
  name: 'Home',
  components: {
    Navbor,
    Aside
  }
}
</script>

<style lang="scss">
.btn-primary{
  background-color: rgba(238, 166, 58, 0.75) !important;
    border-color: rgba(238, 166, 58, 0.75) !important;
    border: none;
}
.alias {cursor: alias;}
.all-scroll {cursor: all-scroll;}
.auto {cursor: auto;}
.cell {cursor: cell;}
.context-menu {cursor: context-menu;}
.col-resize {cursor: col-resize;}
.copy {cursor: copy;}
.crosshair {cursor: crosshair;}
.default {cursor: default;}
.e-resize {cursor: e-resize;}
.ew-resize {cursor: ew-resize;}
.grab {cursor: -webkit-grab; cursor: grab;}
.grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.help {cursor: help;}
.move {cursor: move;}
.n-resize {cursor: n-resize;}
.ne-resize {cursor: ne-resize;}
.nesw-resize {cursor: nesw-resize;}
.ns-resize {cursor: ns-resize;}
.nw-resize {cursor: nw-resize;}
.nwse-resize {cursor: nwse-resize;}
.no-drop {cursor: no-drop;}
.none {cursor: none;}
.not-allowed {cursor: not-allowed;}
.pointer {cursor: pointer;}
.progress {cursor: progress;}
.row-resize {cursor: row-resize;}
.s-resize {cursor: s-resize;}
.se-resize {cursor: se-resize;}
.sw-resize {cursor: sw-resize;}
.text {cursor: text;}
.w-resize {cursor: w-resize;}
.wait {cursor: wait;}
.zoom-in {cursor: zoom-in;}
.zoom-out {cursor: zoom-out;}
.color-gold{
  color:rgba(238, 166, 58, 0.75) !important;
}
.color-white{
  color:white !important;
}
.bg-gold{
  background-color:rgba(238, 166, 58, 0.75) !important;
}
.my-card-header{

  align-items: center;
  border-radius: 10px 10px 0 0;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 60px;
  border-bottom:1px lightgray solid ;
}
.form-input-search{
  background-color: #f4f5f7;
  padding-left: 50px ;
  height: 2.875rem;
  border-radius: 23px;
  border: 0 none;
}
@for $i from 1 through 1000 {
  $maxWidth: 1px * $i ;
  .max-w-px-#{$i} { 
      max-width: $maxWidth !important;
  }
  @media only screen and (max-width: 768px) {
  /* For tablets: */
    .max-w-px-#{$i} { 
        max-width: 100% !important;
    }
  }
}
.my-dropdown{
  margin-left: 10px;
    ::-webkit-input-placeholder { /* Edge */
    color: rgba(238, 166, 58, 0.75);
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(238, 166, 58, 0.75);
  }

  ::placeholder {
    color: rgba(238, 166, 58, 0.75);
  }
  input{
    padding: 5px 15px;
    background-color: #f4f5f7;
    border-radius: 23px;
    border: none;
    color: rgba(238, 166, 58, 0.75);
  }
  select{
    padding: 5px 15px;
    background-color: #f4f5f7;
    border-radius: 23px;
    border: none;
    color: rgba(238, 166, 58, 0.75);
  }
}
</style>
